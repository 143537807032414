/* You can add global styles to this file, and also import other style files */

footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    text-align: center;
}

.nav-item{
    padding:2px;
    margin-left: 7px;
}
body { 
    padding-top: 65px; 
}

.btn-space{
    margin-right:5px;
}